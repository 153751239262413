import { ChangeEvent, FunctionComponent, useEffect, useRef, useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import styled from 'styled-components';
import { ITheme, ThemeKey, getTheme } from "../assets/css/variables";
import { v4 as uuidv4 } from 'uuid';

import { useApi } from "../context/ApiProvider";
import { CreateOrderRequest, AddressCountries, AddressRegions, CourierTypes, OrderTypes, DeliveryMethod, OrderPaymentAuthenticationRequest, OrdersPaymentAuthentication200Response, CreateOrderRequestItemsInner, PrescriptionEntitlementTypes, IntegrationTypes } from "sparrowhub-client-axios";
import { deliveryMethodImages } from "./DeliveryPage";

import { Prescription, formatItemsArray, getEntitlementPrice, getBasketSubtotal, setBilling, setConfig, setOrder, resetState, formatPhoneString, formatPriceFromFloat, formatPriceToInt, ProgressStepKeys } from "../store/scriptSlice";
import { useAppDispatch, useAppSelector } from "../store/hooks";

import { StripeElements, StripeElementsOptionsMode, StripeError } from '@stripe/stripe-js';

import { ContentBlock } from "../components/ContentBlock";
import { Button, ButtonType } from "../components/Button";
import { InputField } from "../components/InputField";
import { Checkbox } from "../components/Checkbox";
import { BackButton } from "../components/BackButton";
import { SelectInput, SelectInputOption } from "../components/SelectInput";
import { Loader } from "../components/Loader";
import { Modal } from "../components/Modal";
import { Alert, AlertIcon, AlertType } from "../components/Alert";
import { Basket } from "../components/Basket";

const closeIcon = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/icon-close.svg`;
const storeIcon = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/store_white.svg`;
const phoneIcon = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/phone_white.svg`;

const useAddressLookup = false;

const loaderSteps: Array<string> = [
  'Confirming payment...',
  'Processing your order...',
  'Almost there...',
  'Done!'
]

const stateOptions: Array<SelectInputOption> = [
  { value: '', label: 'Select state' },
  { value: 'NSW', label: 'NSW' },
  { value: 'QLD', label: 'QLD' },
  { value: 'VIC', label: 'VIC' },
  { value: 'SA', label: 'SA' },
  { value: 'WA', label: 'WA' },
  { value: 'NT', label: 'NT' },
  { value: 'TAS', label: 'TAS' },
  { value: 'ACT', label: 'ACT' }
]

type CheckoutPageProps = {
}

export const CheckoutPage: FunctionComponent<CheckoutPageProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const api = useApi();
  
  // store
  const config = useAppSelector((state) => state.script.config);
  const scripts = useAppSelector((state) => state.script.scripts);
  const delivery = useAppSelector((state) => state.script.delivery);
  const billing = useAppSelector((state) => state.script.billing);

  const theme: ITheme = getTheme(config.theme_key);
  
  // state
  const [showPage, setShowPage] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [apiResult, setApiResult] = useState<any>(null);
  const [billingSame, setBillingSame] = useState(true);
  const [deliveryMethod, setDeliveryMethod] = useState(config.delivery_methods.find((option: DeliveryMethod) => option.code === delivery.delivery_method));
  const [showModal, setShowModal] = useState(false);
  const [paymentInProgress, setPaymentInProgress] = useState(false);
  
  const [paymentAuthenticated, setPaymentAuthenticated] = useState(false);
  const [authenticationInProgress, setAuthenticationInProgress] = useState(false);
  const [authenticationHtml, setAuthenticationHtml] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // state variables managed by useRef to maintain reactivity within event listener
  const [references, _setReferences] = useState({
    order_ref: '',
    transaction_ref: '',
    provider_ref: ''
  })
  const referencesRef = useRef(references);
  const setReferences = (payload: any) => {
    referencesRef.current = payload;
    _setReferences(payload);
  }
  
  const [paymentElementIsInvalid, _setPaymentElementIsInvalid] = useState(true);
  const paymentElementIsInvalidRef = useRef(paymentElementIsInvalid);
  const setPaymentElementIsInvalid = (payload: any) => {
    paymentElementIsInvalidRef.current = payload;
    _setPaymentElementIsInvalid(payload);
  }

  const [authFlow, _setAuthFlow] = useState('');
  const authFlowRef = useRef(authFlow);
  const setAuthFlow = (value: 'frictionless' | 'challenge' | 'no_html') => {
    authFlowRef.current = value;
    _setAuthFlow(value);
  }
  
  const [authSuccess, _setAuthSuccess] = useState(false);
  const authSuccessRef = useRef(authSuccess);
  const setAuthSuccess = (value: boolean) => {
    authSuccessRef.current = value;
    _setAuthSuccess(value);
  }

  // computed
  const useStripePayment = (): boolean => {
    return config.payment_integration_type === IntegrationTypes.Stripe;
  }

  const basketGst = (): number => {
    return 0.00;
  }

  const basketTotal = (): number => {
    return getBasketSubtotal(scripts) + parseFloat(deliveryMethod!.total as unknown as string);
  }

  const paymentIsInvalid = (): boolean => {
    const formEl = (document.getElementById('form_checkout-page_payment-details') as HTMLFormElement);

    if (useStripePayment()) {
      return (
        paymentElementIsInvalidRef.current ||
        billing.billing_firstname === '' ||
        billing.billing_lastname === '' ||
        billing.billing_email === '' ||
        billing.billing_phone === '' ||
        billing.billing_street === '' ||
        billing.billing_city === '' ||
        billing.billing_state_code === '' ||
        billing.billing_postcode === '' || billing.billing_postcode.length !== 4 ||
        (formEl && !formEl.checkValidity())
      )
    } else {
      return (
        billing.card_number === '' ||
        billing.expiry_month === '' ||
        billing.expiry_year === '' || billing.expiry_year.length !== 2 ||
        billing.ccv === '' ||
        billing.card_name === '' ||
        billing.billing_firstname === '' ||
        billing.billing_lastname === '' ||
        billing.billing_email === '' ||
        billing.billing_phone === '' ||
        billing.billing_street === '' ||
        billing.billing_city === '' ||
        billing.billing_state_code === '' ||
        billing.billing_postcode === '' || billing.billing_postcode.length !== 4 ||
        (formEl && !formEl.checkValidity())
      )
    }
  }
  
  const billingData = (): any => {
    return {
      first_name: billing.billing_firstname,
      last_name: billing.billing_lastname,
      email: billing.billing_email,
      phone: billing.billing_phone,
      address: {
        street: JSON.stringify([billing.billing_street]),
        city: billing.billing_city,
        state_code: billing.billing_state_code as AddressRegions,
        postcode: billing.billing_postcode,
        country_code: AddressCountries.Au
      }
    }
  }
  
  const billingDataStripe = (): any => {
    return {
      name: `${billing.billing_firstname} ${billing.billing_lastname}`,
      email: billing.billing_email,
      phone: billing.billing_phone,
      address: {
        line1: JSON.stringify([billing.billing_street]),
        line2: '',
        city: billing.billing_city,
        state: billing.billing_state_code as AddressRegions,
        postal_code: billing.billing_postcode,
        country: AddressCountries.Au
      }
    }
  }

  const deliveryData = (): any => {
    return {
      first_name: delivery.delivery_firstname,
      last_name: delivery.delivery_lastname,
      email: delivery.delivery_email,
      phone: delivery.delivery_phone,
      address: {
        street: JSON.stringify([delivery.delivery_street]),
        city: delivery.delivery_city,
        state_code: delivery.delivery_state_code as AddressRegions,
        postcode: delivery.delivery_postcode,
        country_code: AddressCountries.Au,
      }
    }
  }

  // const authData = (): any => {
  //   return {
  //     order_ref: billing.order_ref,
  //     transaction_ref: billing.transaction_ref,
  //     provider_ref: billing.provider_ref
  //   }
  // }

  const paymentData = (): any => {
    const cardFields = useStripePayment()
      // send dummy data for Stripe payment
      ? {
          card_name: "SparrowScripts",
          card_number: "4242424242424242",
          card_expiry_month: 1,
          card_expiry_year: 29,
          card_cvc: "100",
        }
      // send collected data for Tyro payment
      : {
          card_name: billing.card_name,
          card_number: billing.card_number.replace(/\s/g,''),
          card_expiry_month: parseInt(billing.expiry_month),
          card_expiry_year: parseInt(billing.expiry_year),
          card_cvc: billing.ccv
        }
    
    return {
      total: formatPriceFromFloat(basketTotal()),
      tax: formatPriceFromFloat(parseFloat(deliveryMethod!.tax as unknown as string)), // prescription items have 0 tax, so order total tax is only delivery tax
      payment_method_code: "card",
      ...cardFields
    }
  }

  const itemsData = (): Array<CreateOrderRequestItemsInner> => {
    return scripts.filter((script: Prescription) => script.confirmed).map((script: Prescription) => {
      const itemPrice = formatPriceFromFloat(getEntitlementPrice(script) || 0);
      // const itemTax = script.products[script.selected_product_index].gst_to_consumer ? itemPrice / 10 : 0;
      let item: CreateOrderRequestItemsInner = {
        sku: script.products[script.selected_product_index].barcode,
        name: script.name,
        qty: 1,
        price: itemPrice,
        tax: 0,
        is_prescription: true,
        prescription_first_name: (script.script_firstname && script.script_lastname) ? script.script_firstname : script.customer_firstname,
        prescription_last_name: (script.script_firstname && script.script_lastname) ? script.script_lastname : script.customer_lastname,
        prescription_token_number: script.token,
        prescription_entitlement: script.entitlement as PrescriptionEntitlementTypes,
        prescription_entitlement_number: script.entitlement_number,
        drug_schedule: script.products[script.selected_product_index].drug_schedule,
        supplier_id: String(script.products[script.selected_product_index].supplier_id),
        // gst_to_consumer: itemTax
      }
      return item;
    })
  }

  // methods
  const next = (): void => {
    setShowPage(false);
    setShowLoader(false);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setTimeout(() => {
      navigate('/confirmation');
    }, 300);
  }

  const initStripeElements = (): StripeElements | null => {
    console.log('initStripeElements')
    const options: StripeElementsOptionsMode = {
      mode: 'payment',
      amount: formatPriceToInt(basketTotal()),
      currency: 'aud',
      paymentMethodCreation: 'manual',
      // appearance: {}
    };

    if (window.stripe === null) {
      console.warn('Unable to init Stripe Elements, Stripe is null.');
      return null;
    } else {
      console.log('stripe elements ready')
      // Set up Stripe.js and Elements to use in checkout form
      const elements = window.stripe.elements(options);
      
      // Create the Payment Element
      const paymentElement = elements.create('payment', {
        fields: {
          billingDetails: 'never'
        }
      });

      // Watch element for validity
      paymentElement.on('change', (event) => {
        if (event.complete) {
          setPaymentElementIsInvalid(false);
        } else {
          setPaymentElementIsInvalid(true);
        }
      })

      // Mount element 
      paymentElement.mount('#stripe-payment-element');

      // Return elements object
      return elements;
    }
  }

  const handlePayNow = (): void => {
    if (useStripePayment()) {
      handleStripePayment();
    } else {
      handleTyroPayment();
    }
  }

  const handleStripePayment = async (): Promise<void> => {
    setPaymentInProgress(true);
    setErrorMessage('');

    console.log('handleStripePayment')
    if (window.stripe === null || window.stripeElements === null) {
      console.warn('Unable to init Stripe Elements, Stripe or Elements are not initialised.');
    } else {
      // submit elements
      const elementsResult = await window.stripeElements.submit();
      if (elementsResult.error) {
        handleStripeError(elementsResult.error);
        return
      }

      // create payment method
      const createPaymentMethodResult = await window.stripe.createPaymentMethod({
        elements: window.stripeElements,
        params: {
          billing_details: billingDataStripe()
        },
      });
      if (createPaymentMethodResult.error) {
        handleStripeError(createPaymentMethodResult.error);
        return
      }

      const paymentMethod = createPaymentMethodResult.paymentMethod;
      console.log('payment method!', paymentMethod)

      // set references
      const uuid = uuidv4();
      setReferences({
        transaction_ref: uuid,
        order_ref: uuid,
        provider_ref: paymentMethod.id
      });

      // handle process new order
      handleCompleteProcess();
    }
  }

  const handleStripeError = (error: StripeError): void => {
    setPaymentInProgress(false);
    setErrorMessage(error.message || 'An unexpected error has occurred while processing your payment. Please try again.');
  }

  const handleShow3dsModal = async (): Promise<void> => {
    // submit injected Tyro setup form
    const script = document.getElementById('authenticate-payer-script');
    if (script) {
      // clear error message
      setErrorMessage('');

      const frictionlessFormId = 'threedsFrictionLessRedirectForm';
      const scriptText = (script as any).text;

      if (scriptText.includes(frictionlessFormId)) {
        // frictionless flow: skip modal and extract results from form element
        setAuthFlow('frictionless');
        const formEl = document.getElementById(frictionlessFormId);
        const gatewayRecommendation = (document.getElementsByName('response.gatewayRecommendation')[0] as HTMLInputElement).value;
        
        // proceed based on gatewayRecommendation as normal
        if (formEl && gatewayRecommendation === 'PROCEED') {
          handle3dsSuccess();
        } else {
          handle3dsFailure();
        }
      } else {
        // challenge flow: trigger script to show 3DS challenge
        setAuthFlow('challenge');
        eval(scriptText);

        // attach listener for response event
        window.addEventListener('message', handle3dsResponse, false);

        // show modal
        setTimeout(() => {
          setShowModal(true);
        }, 300);
      }
    }
  }

  const handleReset3dsModal = (): void => {
    setShowModal(false);
    setPaymentInProgress(false);
    setAuthenticationHtml('');
    window.removeEventListener('message', handle3dsResponse, false);
  }

  const handle3dsResponse = (event: MessageEvent): void => {
    if (event.origin != "https://mtf.gateway.mastercard.com") { return; }  

    const data = JSON.parse(event.data);
    if (data.result === 'SUCCESS') {
      handle3dsSuccess();
    } else {
      handle3dsFailure();
    }
  }

  const handle3dsSuccess = (): void => {
    setAuthSuccess(true);
    trackAuthenticatePayment();

    handleCompleteProcess();
    
    setTimeout(() => {
      handleReset3dsModal();
    }, 300);
  }
  
  const handle3dsFailure = (): void => {
    setAuthSuccess(false);
    trackAuthenticatePayment();

    handleReset3dsModal();
    setPaymentInProgress(false);
    setErrorMessage('We were unable to validate your payment method. Please try again with a different payment method.');
    setTimeout(() => {
      window.scrollTo({ top: 9999, left: 0, behavior: 'smooth' });
    }, 300);
  }

  const trackAuthenticatePayment = (): void => {
    gtag('event', 'authenticate_payment', {
      'authentication_flow': authFlowRef.current,
      'authentication_success': authSuccessRef.current,
      'transaction_reference': referencesRef.current.transaction_ref
    });
  }

  const handleTyroPayment = async (): Promise<void> => {
    // if all necessary fields not provided, return early
    if (paymentIsInvalid()) {
      return
    }

    // set state
    setPaymentInProgress(true);
    setErrorMessage('');
    setAuthenticationInProgress(true);

    // generate reCAPTCHA token
    await grecaptcha.enterprise.ready(async () => {
      const recaptchaToken = await grecaptcha.enterprise.execute(config.recaptcha_key, {action: 'checkout'});

      const requestBody: OrderPaymentAuthenticationRequest = {
        security_token: recaptchaToken,
        partner_id: config.partner_id!,
        // card_number: billing.card_number.replace(/\s/g,'')
        browser: {
          challenge_window_size: document.documentElement.clientWidth > 600 ? '390_X_400' : '250_X_400',
          user_agent: window.navigator.userAgent,
          language: window.navigator.language,
          timezone: new Date().getTimezoneOffset().toString(),
          screen_color_depth: window.screen.colorDepth,
          screen_width: window.screen.width,
          screen_height: window.screen.height,
          is_java_enabled: false,
          is_javascript_enabled: true, 
        },
        billing: billingData(),
        delivery: deliveryData(),
        payment: paymentData()
      }

      console.log(requestBody)
  
      api.ordersPaymentAuthentication(requestBody).then((response) => {
        setAuthenticationInProgress(false);
        const typedResponse: OrdersPaymentAuthentication200Response = { data: JSON.parse((response.data as any).data) };

        if (typedResponse.data) {
          setPaymentAuthenticated(typedResponse.data.proceed_with_payment);

          console.log(typedResponse.data)

          if (typedResponse.data.proceed_with_payment) {
            setReferences({
              transaction_ref: typedResponse.data.transaction_reference,
              order_ref: typedResponse.data.order_reference,
              provider_ref: typedResponse.data.provider_reference || ''
            });

            if (typedResponse.data.payment_authentication_html) {
              // if authentication URL provided, show modal
              setAuthenticationHtml(typedResponse.data.payment_authentication_html);
              setTimeout(() => {
                handleShow3dsModal();
              }, 300);
            } else {
              // else no html flow (proceed as if frictionless)
              setAuthFlow('no_html');
              handle3dsSuccess();
            }
          } else {
            setErrorMessage('We were unable to validate your payment method. Please try again with a different payment method.');
            setPaymentInProgress(false);
            setTimeout(() => {
              window.scrollTo({ top: 9999, left: 0, behavior: 'smooth' });
            }, 300);
          }
        }

      })
      .catch(error => {
        console.log('error!');
        console.log(error);
        setPaymentInProgress(false);
        setErrorMessage('We were unable to validate your payment method. Please try again.');
        setTimeout(() => {
          window.scrollTo({ top: 9999, left: 0, behavior: 'smooth' });
        }, 300);
        setAuthenticationInProgress(false);
      })
    });
  }

  const handleCompleteProcess = async (): Promise<void> => {
    // manually submit GA events
    gtag('event', 'form_submit', {
      'form_id': 'form_checkout-page_payment-details',
      'form_name': 'Payment Information',
      'form_submit_text': 'Pay Now'
    });

    gtag('event', 'add_payment_info', {
      'currency': 'AUD',
      'value': getBasketSubtotal(scripts),
      'items': formatItemsArray(scripts)
    });

    gtag('event', 'purchase', {
      'currency': 'AUD',
      'transaction_id': referencesRef.current.order_ref,
      'value': getBasketSubtotal(scripts),
      'items': formatItemsArray(scripts)
    });

    // set state
    setShowPage(false);
    setShowLoader(true);

    // generate reCAPTCHA token
    await grecaptcha.enterprise.ready(async () => {
      const token = await grecaptcha.enterprise.execute(config.recaptcha_key, {action: 'checkout'});
      handleCreateOrder(token);
    });
  }

  const handleCreateOrder = (recaptchaToken: string): void => {
    let requestBody: CreateOrderRequest = {
      order_type_code: OrderTypes.Sale,
      security_token: recaptchaToken,
      partner: {
        id: config.partner_id!,
        location_code: config.location_code!
      },
      customer: {
        first_name: delivery.delivery_firstname,
        last_name: delivery.delivery_lastname,
        email: delivery.delivery_email,
        phone: delivery.delivery_phone,
      },
      delivery: {
        courier_type_code: deliveryMethod!.courier_type_code as CourierTypes,
        delivery_type_code: deliveryMethod!.delivery_type_code,
        total: formatPriceFromFloat(parseFloat(deliveryMethod!.total as unknown as string)),
        tax: formatPriceFromFloat(parseFloat(deliveryMethod!.tax as unknown as string)),
        ...deliveryData() as any,
      },
      billing: billingData(),
      payment: {
        auth: referencesRef.current,
        ...paymentData()
      },
      items: itemsData(),
    };

    console.log('process new order request body');
    console.log(requestBody);

    api.createOrder(requestBody).then((response) => {
      setApiResult(response);
      
      // set order data for confirmation page, then clear store
      const order = response.data.data;
      dispatch(setOrder({
        order_number: order.order_number,
        delivery_method: delivery.delivery_method,
        delivery_method_name: deliveryMethod!.name
      }))
      dispatch(resetState());
      next();
    })
    .catch(error => {
      console.log('error');
      console.log(error);
      handleError(error.response);
    })
  }

  const handleError = (apiResponse: any): void => {
    setShowPage(false);
    setShowLoader(false);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    dispatch(setConfig({ error: apiResponse.data }))
    
    setTimeout(() => {
      navigate('/checkout-error');
    }, 300);
  }

  const formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD'
  });
  const formatPrice = (price: number | string): string => {
    let priceNum = typeof price === 'number'
      ? price
      : parseFloat(price);
    return formatter.format(priceNum);
  }

  // handle billing address
  useEffect(() => {
    if (billingSame) {
      dispatch(setBilling({
        billing_street: delivery.delivery_street,
        billing_city: delivery.delivery_city,
        billing_state_code: delivery.delivery_state_code,
        billing_postcode: delivery.delivery_postcode,
        billing_firstname: delivery.delivery_firstname,
        billing_lastname: delivery.delivery_lastname,
        billing_email: delivery.delivery_email,
        billing_phone: delivery.delivery_phone,
      }))
    } else {
      dispatch(setBilling({
        billing_street: '',
        billing_city: '',
        billing_state_code: '',
        billing_postcode: '',
        billing_firstname: '',
        billing_lastname: '',
        billing_email: '',
        billing_phone: '',
      }))
    }
  }, [ billingSame ])

  // init page
  useEffect(() => {
    setTimeout(() => {
      dispatch(setConfig({ progress_step_key: ProgressStepKeys.Checkout }));
      setShowPage(true);
      window.scrollTo(0, 0);

      if (config.has_landed) {
        gtag('event', 'begin_checkout', {
          'currency': 'AUD',
          'value': getBasketSubtotal(scripts),
          'items': formatItemsArray(scripts)
        });
      }

      if (useStripePayment()) {
        window.stripeElements = initStripeElements();
      }
    }, 10);
  }, []);

  return (
    <>
      {!config.has_landed ?
        <Navigate to="/" />
      :
        <>
          {(config.delivery_methods.length === 0 || delivery.delivery_method === undefined) ?
            <Navigate to="/delivery" />
          :
            <>
              <StyledCheckoutPage className={`pageTransition ${!showPage && 'hidden'}`}>
                <Basket>
                  <div className="Basket_section">
                    {scripts.map((script: Prescription, i: number) => {
                      if (script.confirmed) {
                        return (
                          <div className="Basket_row" key={i}>
                            <p><span>{script.name}</span><span className="bold">{formatPrice(getEntitlementPrice(script) as number)}</span></p>
                          </div>
                        )
                      } else {
                        return null
                      }
                    })}
                  </div>
                  <div className="Basket_section">
                    <div className="Basket_row">
                      <p><span>Sub-Total</span><span className="bold">{formatPrice(getBasketSubtotal(scripts))}</span></p>
                    </div>
                    <div className="Basket_row">
                      <p><span>Delivery</span><span className="bold">{formatPrice(deliveryMethod!.total)}</span></p>
                    </div>
                  </div>
                  <div className="Basket_section Basket_total">
                    <div className="Basket_row">
                      <p><span className="bold">Total</span><span className="bold">{formatPrice(basketTotal())}</span></p>
                    </div>
                  </div>
                </Basket>

                <ContentBlock id="dispensingPharmacy">
                  {theme.images.icon &&
                    <img className="pharmacy-logo" src={theme.images.icon} alt="" />
                  }
                  <p className="bold header">Your order will be dispensed by {config.location_name}</p>
                  <div className="detail">
                    <img src={storeIcon} alt="" />
                    <p>{config.location_address}</p>
                  </div>
                  <div className="detail">
                    <img src={phoneIcon} alt="" />
                    <p>{formatPhoneString(config.location_phone || '')}</p>
                  </div>
                  <p className="bold">
                    {deliveryMethod!.code === 'pickup' ? 'Pick up your order from:' : 'Your order will be delivered by:'}
                    <img className="courier-logo" src={deliveryMethod!.code === 'pickup' ? theme.images.logo : deliveryMethodImages[deliveryMethod!.code as keyof typeof deliveryMethodImages]} alt={deliveryMethod!.name} />
                  </p>
                  
                </ContentBlock>

                <ContentBlock heading="Payment Information">
                  <form name="Payment Information" id="form_checkout-page_payment-details">
                    {useStripePayment() ?
                      <div id="stripe-payment-element"></div>
                    :
                      <>
                        <InputField type="text" label="Credit Card Number" name="cardnumber" autocomplete="cc-number" regex={/^\d+$/} value={billing.card_number} onChange={(e: ChangeEvent) => { dispatch(setBilling({ card_number: (e.target as HTMLInputElement).value })); setPaymentAuthenticated(false) }} required />
                        <div className="Payment_row">
                          <InputField type="text" label="Expiry" name="ccmonth" autocomplete="cc-exp-month" regex={/^\d{0,2}$/} placeholder="MM" value={billing.expiry_month} onChange={(e: ChangeEvent) => { dispatch(setBilling({ expiry_month: (e.target as HTMLInputElement).value })); setPaymentAuthenticated(false) }} required />
                          <InputField type="text" label="Year" name="ccyear" autocomplete="cc-exp-year" regex={/^\d{0,2}$/} placeholder="YY" value={billing.expiry_year} onChange={(e: ChangeEvent) => { dispatch(setBilling({ expiry_year: (e.target as HTMLInputElement).value })); setPaymentAuthenticated(false) }} required />
                          <InputField type="text" label="CVC" name="cvc" autocomplete="cc-csc" regex={/^\d{0,4}$/} value={billing.ccv} onChange={(e: ChangeEvent) => { dispatch(setBilling({ ccv: (e.target as HTMLInputElement).value })); setPaymentAuthenticated(false) }} required />
                        </div>
                        <InputField type="text" label="Name as it appears on card" name="ccname" autocomplete="cc-name" value={billing.card_name} onChange={(e: ChangeEvent) => { dispatch(setBilling({ card_name: (e.target as HTMLInputElement).value })); setPaymentAuthenticated(false) }} required />
                      </>
                    }

                    <Checkbox id="terms" selected={billingSame} onChange={() => setBillingSame(billing => !billing)}>
                      <>Billing details are the same as delivery details</>
                    </Checkbox>

                    {!billingSame &&
                      <div className="Checkout_billingAddress">
                        <div className="inputColumns inputColumns_desktopOnly">
                          <InputField type="text" label="First Name" name="fname" autocomplete="given-name" value={billing.billing_firstname} onChange={(e: ChangeEvent) => dispatch(setBilling({ billing_firstname: (e.target as HTMLInputElement).value}))} required />
                          <InputField type="text" label="Last Name" name="lname" autocomplete="family-name" value={billing.billing_lastname} onChange={(e: ChangeEvent) => dispatch(setBilling({ billing_lastname: (e.target as HTMLInputElement).value}))} required />
                          <InputField type="email" label="Email" name="email" autocomplete="email" value={billing.billing_email} onChange={(e: ChangeEvent) => dispatch(setBilling({ billing_email: (e.target as HTMLInputElement).value}))} required />
                          <InputField type="tel" label="Mobile Number" name="mobile" autocomplete="tel" regex={/^[\d,+]+$/} value={billing.billing_phone} onChange={(e: ChangeEvent) => dispatch(setBilling({ billing_phone: (e.target as HTMLInputElement).value}))} required />
                        </div>
                      </div>
                    }
                  </form>
                </ContentBlock>

                <Button text="Pay Now" type={ButtonType.Primary} disabled={paymentIsInvalid() || authenticationInProgress || paymentInProgress} loading={authenticationInProgress} onClick={handlePayNow} />

                <div className={`Checkout_modalParent elementTransition ${!showModal && 'hidden'}`}>
                  <Modal show={true}>
                    <img className="Modal_close button" src={closeIcon} onClick={handleReset3dsModal} />
                    
                    <div dangerouslySetInnerHTML={{__html: authenticationHtml}}>

                    </div>
                  </Modal>
                </div>

                {errorMessage &&
                  <div style={{ marginTop: '20px' }}>
                    <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                      <p>{errorMessage}</p>
                    </Alert>
                  </div>
                }
              </StyledCheckoutPage>
          
              <Loader show={showLoader} steps={loaderSteps} canComplete={apiResult !== null} />
            </>
          }
        </>
      }
    </>
  );
}

const StyledCheckoutPage = styled.div`
  #dispensingPharmacy {
    position: relative;
    z-index: 1;
    padding: 25px 0;
    margin-bottom: 40px;
    
    &:after {
      content: "";
      background: ${props => props.theme.colours.backgroundDark};
      position: absolute;
      top: 0;
      bottom: 0;
      left: -100vw;
      width: 200vw;
      z-index: -1;
    }

    .pharmacy-logo {
      height: 33px;
      width: auto;
    }

    .detail {
      display: grid;
      grid-template-columns: 21px 1fr;

      p {
        margin-top: 0;
      }
    }

    p {
      font-size: 0.75rem; // 12px
      font-weight: 300;
      color: ${props => props.theme.colours.primaryDark};
      
      &.header {
        font-size: 0.875rem; // 14px
        display: block;
      }
    }

    .bold:not(.header) {
      margin-top: 15px;
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }

    .courier-logo {
      width: 90px;
      margin-left: 8px;
    }
  }

  .RadioButton {
    display: grid;
    grid-template-columns: 40px 1fr;
    align-items: center;
    width: 100%;

    img {
      width: auto;
      height: 22px;
      object-fit: contain;
      padding-right: 20px;
    }

    &:last-child {
      margin-bottom: 20px;
    }
  }

  .Payment_row {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    gap: 10px;

    :nth-child(2) {
      label {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  .Checkbox {
    margin: 15px 0 0 0;
  }

  .Checkout_billingAddress {
    margin-top: 20px;
    margin-bottom: -16px;

    .Checkout_addressInput {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0 16px;

      .InputField {
        margin-bottom: 0;
      }
    }
  }

  .Checkout_modalParent {
    z-index: 999;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(0) !important;

    &.hidden {
      pointer-events: none;

      * {
        pointer-events: none;
      }
    }

    .Modal_close {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    iframe {
      border: none;
      max-width: 100%;
    }
  }
`;
