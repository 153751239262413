import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import styled from 'styled-components';
import { useNavigate, Navigate } from "react-router-dom";
import { ITheme, ThemeKey, getTheme } from "../assets/css/variables";

import { useApi } from "../context/ApiProvider";
import { AddressCountries, AddressRegions, DeliveryMethod, PublicGetDeliveryMethodsRequest } from "sparrowhub-client-axios";

import { Prescription, setDelivery, setConfig, getEntitlementPrice, formatItemsArray, formatPhoneString, formatPriceFromFloat, ProgressStepKeys } from '../store/scriptSlice'
import { useAppDispatch, useAppSelector } from "../store/hooks";

import { CourierTypes, DeliveryTypes } from "sparrowhub-client-axios";

import { ContentBlock } from "../components/ContentBlock";
import { Button, ButtonType } from "../components/Button";
import { InputField } from "../components/InputField";
import { RadioGroup } from "../components/RadioGroup";
import { SelectInput, SelectInputOption } from "../components/SelectInput";
import { BackButton } from "../components/BackButton";
import { Alert, AlertIcon, AlertType } from "../components/Alert";
import { SimpleSpinner } from "../components/SimpleSpinner";

const tickIcon = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/TickWhite.svg`;
const doordashLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/logo-doordash.png`;
const auspostExpressLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/logo-auspost-express.png`;
const auspostLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/logo-auspost.png`;
const tollLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/logo-toll.png`;
const aramexLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/logo-aramex.png`;

const useAddressLookup = false;

const stateOptions: Array<SelectInputOption> = [
  { value: '', label: 'Select state', disabled: true },
  { value: 'NSW', label: 'NSW' },
  { value: 'QLD', label: 'QLD' },
  { value: 'VIC', label: 'VIC' },
  { value: 'SA', label: 'SA' },
  { value: 'WA', label: 'WA' },
  { value: 'NT', label: 'NT' },
  { value: 'TAS', label: 'TAS' },
  { value: 'ACT', label: 'ACT' }
]

export const deliveryMethodImages = {
  'doordash': doordashLogo,
  'auspost-express': auspostExpressLogo,
  'auspost-standard': auspostLogo,
  'toll': tollLogo,
  'aramex': aramexLogo,
}

export const deliveryMethodLabels = {
  'doordash': 'Same-Day Delivery',
  'auspost-express': 'Express Delivery',
  'auspost-standard': 'Standard Delivery',
  'pickup': 'Click & Collect',
  'toll': 'Express Delivery',
  'aramex': 'Express Delivery',
}

type DeliveryPageProps = {
}

export const DeliveryPage: FunctionComponent<DeliveryPageProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const api = useApi();

  // store
  const config = useAppSelector((state) => state.script.config);
  const scripts = useAppSelector((state) => state.script.scripts);
  const delivery = useAppSelector((state) => state.script.delivery);

  const theme: ITheme = getTheme(config.theme_key);

  // state
  const [showPage, setShowPage] = useState(false);
  const [showDeliveryContent, setShowDeliveryContent] = useState(config.delivery_methods.length !== 0);
  const [showPharmacy, setShowPharmacy] = useState(config.delivery_methods.length !== 0);
  const [showDelivery, setShowDelivery] = useState(config.delivery_methods.length !== 0);
  const [showProceed, setShowProceed] = useState(config.delivery_methods.length !== 0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentStreet, setCurrentStreet] = useState(delivery.delivery_street);
  const [currentCity, setCurrentCity] = useState(delivery.delivery_city);
  const [currentPostcode, setCurrentPostcode] = useState(delivery.delivery_postcode);
  const [currentState, setCurrentState] = useState(delivery.delivery_state_code);

  // computed
  const addressIsInvalid = (): boolean => {
    const formEl = (document.getElementById('form_delivery-page_delivery-details') as HTMLFormElement);
    return (
      delivery.delivery_firstname === '' ||
      delivery.delivery_lastname === '' ||
      delivery.delivery_email === '' ||
      delivery.delivery_phone === '' ||
      delivery.delivery_street === '' ||
      delivery.delivery_city === '' ||
      delivery.delivery_postcode === '' || delivery.delivery_postcode.length !== 4 ||
      delivery.delivery_state_code === '' ||
      (formEl && !formEl.checkValidity())
    )
  }

  const addresseeOptions = (): Array<SelectInputOption> => {
    const optionStrings: Array<string> = [];
    const options: Array<SelectInputOption> = [{
      value: '',
      label: 'Select addressee',
      disabled: true
    }];
    scripts.forEach((script: Prescription) => {
      const string = `${script.customer_firstname},${script.customer_lastname}`;
      if (optionStrings.includes(string) === false && script.confirmed) {
        optionStrings.push(string);
        options.push({
          value: string,
          label: `${script.customer_firstname} ${script.customer_lastname}`
        })
      }
    })
    return options;
  }

  const itemsData = (): Array<any> => {
    return scripts.map((script: Prescription) => {
      return {
        sku: script.products[script.selected_product_index].barcode,
        name: script.name,
        qty: 1,
        price: formatPriceFromFloat(getEntitlementPrice(script) || 0),
        tax: 0,
        is_prescription: true
      }
    })
  }

  // methods
  const next = (): void  => {
    // manually submit GA events
    gtag('event', 'form_submit', {
      'form_id': 'form_delivery-page_delivery-method',
      'form_name': 'Delivery and Click & Collect Options',
      'form_submit_text': 'Proceed to Checkout'
    });

    const deliveryMethod = config.delivery_methods.find((option: DeliveryMethod) => option.code === delivery.delivery_method);

    gtag('event', 'add_shipping_info', {
      'currency': 'AUD',
      'value': parseFloat(deliveryMethod!.total as unknown as string),
      'shipping_tier': delivery.delivery_method,
      'items': formatItemsArray(scripts)
    });

    // handle next
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setShowPage(false);
    setTimeout(() => {
      navigate('/checkout');
    }, 300);
  }

  const handleHideDeliveryContent = (): void => {
    const shouldHide = (
      currentStreet !== delivery.delivery_street ||
      currentCity !== delivery.delivery_city ||
      currentPostcode !== delivery.delivery_postcode ||
      currentState !== delivery.delivery_state_code
    )

    if (shouldHide) {
      // remove delivery method values from store
      dispatch(setConfig({ delivery_methods: [] }));
      dispatch(setDelivery({ delivery_method: undefined }));

      // hide UI
      // window.scrollTo({
      //   top: 0,
      //   left: 0,
      //   behavior: 'smooth'
      // });

      setShowPharmacy(false);
      setShowDelivery(false);
      setShowProceed(false);

      setTimeout(() => {
        setShowDeliveryContent(false);
      }, 200);
    }
  }

  const handleSetAddressee = (addresseeString: string): void => {
    const split = addresseeString.split(',');
    dispatch(setDelivery({
      delivery_firstname: split[0],
      delivery_lastname: split[1],
    }))
  }

  const handleShowDelivery = async (): Promise<void> => {
    // manually submit GA event
    gtag('event', 'form_submit', {
      'form_id': 'form_delivery-page_delivery-details',
      'form_name': 'Delivery details',
      'form_submit_text': 'View Delivery & Collection Options'
    });

    // set state
    setErrorMessage('');
    setIsLoading(true);

    // generate reCAPTCHA token
    await grecaptcha.enterprise.ready(async () => {
      const token = await grecaptcha.enterprise.execute(config.recaptcha_key, {action: 'get_delivery_methods'});
      handleGetDeliveryMethods(token);
    });
  }

  const handleGetDeliveryMethods = async (token: string): Promise<void> => {
    const requestBody: PublicGetDeliveryMethodsRequest = {
      security_token: token,
      is_live_quote: true,
      delivery_method_codes: ['auspost-standard', 'auspost-express', 'doordash', 'pickup'],
      partner: {
        id: config.partner_id!,
        location_code: config.location_code!
      },
      customer: {
        first_name: delivery.delivery_firstname,
        last_name: delivery.delivery_lastname,
        email: delivery.delivery_email,
        phone: delivery.delivery_phone
      },
      delivery: {
        first_name: delivery.delivery_firstname,
        last_name: delivery.delivery_lastname,
        email: delivery.delivery_email,
        phone: delivery.delivery_phone,
        address: {
          street: JSON.stringify([delivery.delivery_street]),
          city: delivery.delivery_city,
          state_code: delivery.delivery_state_code as AddressRegions,
          postcode: delivery.delivery_postcode,
          country_code: AddressCountries.Au,
        }
      },
      items: itemsData()
    }

    

    api.publicGetDeliveryMethods(requestBody).then((response) => {
      // handle success
      setIsLoading(false);
      const data = JSON.parse(response.data.data as any);
      
      // set delivery methods in store
      dispatch(setConfig({ delivery_methods: data }));

      // set UI state
      setShowDeliveryContent(true);
      setTimeout(() => {
        window.scrollTo({
          top: 9999,
          left: 0,
          behavior: 'smooth'
        });
      }, 100);
      
      setTimeout(() => {
        setShowPharmacy(true);
      }, 200);

      setTimeout(() => {
        setShowDelivery(true);
      }, 400);
      
      setTimeout(() => {
        setShowProceed(true);
      }, 600);
    })
    .catch(error => {
      // handle error
      setIsLoading(false);
      setErrorMessage(error.response.data);
      dispatch(setConfig({ delivery_methods: [] }));

      console.log('error!')
      console.log(error)
    })
  }

  const formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD'
  });

  const formatPrice = (price: number | string): string => {
    let priceNum = typeof price === 'number'
      ? price
      : parseFloat(price);
    return formatter.format(priceNum);
  }

  useEffect(() => {
    setTimeout(() => {
      dispatch(setConfig({ progress_step_key: ProgressStepKeys.Delivery }));
      setShowPage(true);
      window.scrollTo(0, 0);
    }, 10);
  }, []);
  
  return (
    <>
      {!config.has_landed ?
        <Navigate to="/" />
      :
        <>
          {scripts.filter((script: Prescription) => script.confirmed).length === 0 ?
            <Navigate to="/prescription" />
          :
            <>
              <StyledDeliveryPage className={`pageTransition ${!showPage && 'hidden'}`}>
                <ContentBlock heading="Delivery details">
                  <form name="Delivery details" id="form_delivery-page_delivery-details">
                    {/* <SelectInput 
                      id="addressee" 
                      label="Addressee Name" 
                      options={addresseeOptions()} 
                      value={delivery.delivery_firstname} 
                      onChange={(e: ChangeEvent) => handleSetAddressee((e.target as HTMLInputElement).value)}
                      required
                    /> */}

                    <div className="inputColumns inputColumns_desktopOnly">
                      <InputField type="text" label="First Name" name="fname" autocomplete="given-name" value={delivery.delivery_firstname} onChange={(e: ChangeEvent) => dispatch(setDelivery({ delivery_firstname: (e.target as HTMLInputElement).value}))} required />
                      <InputField type="text" label="Last Name" name="lname" autocomplete="family-name" value={delivery.delivery_lastname} onChange={(e: ChangeEvent) => dispatch(setDelivery({ delivery_lastname: (e.target as HTMLInputElement).value}))} required />
                      <InputField type="email" label="Email" name="email" autocomplete="email" value={delivery.delivery_email} onChange={(e: ChangeEvent) => dispatch(setDelivery({ delivery_email: (e.target as HTMLInputElement).value}))} required />
                      <InputField type="tel" label="Mobile Number" name="mobile" autocomplete="tel" regex={/^[\d,+]+$/} value={delivery.delivery_phone} onChange={(e: ChangeEvent) => dispatch(setDelivery({ delivery_phone: (e.target as HTMLInputElement).value}))} required />
                    </div>

                    <InputField type="text" label={useAddressLookup ? 'Look Up Street Address' : 'Street Address'} name="address" autocomplete="street-address" value={delivery.delivery_street} onChange={(e: ChangeEvent) => { dispatch(setDelivery({ delivery_street: (e.target as HTMLInputElement).value})); handleHideDeliveryContent() }} required />
                    {useAddressLookup &&
                      <p>Or enter <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>manually</span></p>
                    }

                    <InputField type="text" label="Suburb" name="city" autocomplete="address-level2" value={delivery.delivery_city} onChange={(e: ChangeEvent) => { dispatch(setDelivery({ delivery_city: (e.target as HTMLInputElement).value})); handleHideDeliveryContent() }} required />
                    <div className="Delivery_addressInput inputColumns">
                      <InputField type="text" label="Postcode" regex={/^\d{0,4}$/} name="postal" autocomplete="postal-code" value={delivery.delivery_postcode} onChange={(e: ChangeEvent) => { dispatch(setDelivery({ delivery_postcode: (e.target as HTMLInputElement).value})); handleHideDeliveryContent() }} required />
                      <SelectInput 
                        id="state"
                        label="State"
                        name="region"
                        autocomplete="address-level1"
                        options={stateOptions}
                        value={delivery.delivery_state_code}
                        onChange={(e: ChangeEvent) => { dispatch(setDelivery({ delivery_state_code: (e.target as HTMLInputElement).value})); handleHideDeliveryContent() }}
                        required
                      />
                    </div>
                  </form>
                </ContentBlock>

                {!showDeliveryContent ?
                  <>
                    <Button text="View Delivery & Collection Options" type={ButtonType.Primary} disabled={addressIsInvalid() || isLoading} loading={isLoading} onClick={handleShowDelivery} />
                    {/* <div className={`simpleSpinner ${!isLoading && 'hidden'}`}></div> */}
                    <SimpleSpinner show={isLoading} />

                    {errorMessage &&
                      <div style={{ marginTop: '20px' }}>
                        <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                          <p>{errorMessage}<br/>Please try again.</p>
                        </Alert>
                      </div>
                    }
                  </>
                :
                  <>
                    {/* <div className={`elementTransition ${!showPharmacy && 'hidden'}`}>
                      <ContentBlock heading="Dispensing Pharmacy" id="dispensingPharmacy">
                        <img src={theme.images.logo} alt="" />
                        <p><span className="bold">Address:</span> {config.location_address}</p>
                        <p><span className="bold">Phone:</span> {formatPhoneString(config.location_phone || '')}</p>
                      </ContentBlock>
                    </div> */}

                    <div className={`elementTransition ${!showDelivery && 'hidden'}`}>
                      <ContentBlock heading="Delivery & Collection Options">
                        <form name="Delivery & Collection Options" id="form_delivery-page_shipping-method"></form>
                        <RadioGroup value={delivery.delivery_method} label="You are eligible for the following shipping and collection methods:">
                          {config.delivery_methods.map((option: DeliveryMethod, i: number) => {
                            return (
                              <div key={option.code} onClick={() => dispatch(setDelivery({ delivery_method: option.code }))} className={`RadioButton ${delivery.delivery_method === option.code && 'selected'}`}>
                                <input type="radio" name="shipping" id={option.code} value={option.code} style={{ backgroundImage: `url(${tickIcon})` }} />
                                <div className="RadioButton_flex">
                                  <div className="RadioButton_label">
                                    <p>{deliveryMethodLabels[option.code as keyof typeof deliveryMethodImages]}</p>
                                    <img src={option.code === 'pickup' ? theme.images.logo : deliveryMethodImages[option.code as keyof typeof deliveryMethodImages]} alt={option.name} />
                                  </div>
                                  <p>{option.code === 'pickup' ? `Ready for collection in 3 hours` : option.delivery_estimate}</p>
                                </div>
                                <p className="bold">{formatPrice(option.total)}</p>
                              </div>
                            )
                          })}
                        </RadioGroup>
                      </ContentBlock>
                    </div>

                    <div className={`elementTransition ${!showProceed && 'hidden'}`}>
                      <Button text="Proceed to Checkout" type={ButtonType.Primary} onClick={next} disabled={addressIsInvalid() ||  delivery.delivery_method === ''} />
                    </div>
                  </>
                }
              </StyledDeliveryPage>
            </>
          }
        </>
      }
    </>
  );
}

const StyledDeliveryPage = styled.div`
  #dispensingPharmacy {
    position: relative;
    
    img {
      position: absolute;
      top: 30px;
      right: 30px;
      width: 74px;

      @media screen and (max-width: 600px) {
        top: 19px;
        right: 16px;
      }
    }

    p {
      font-size: 0.625rem; // 10px
      display: grid;
      grid-template-columns: 55px 1fr;

      span {
        display: inline-block;
        width: 55px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .RadioButton {
    display: grid;
    grid-template-columns: 40px 1fr 50px;
    align-items: center;
    width: 100%;
    height: 78px;

    .RadioButton_flex {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 3px;

      .RadioButton_label {
        display: flex;
        align-items: center;
        height: 18px;
        
        p {
          font-weight: 500 !important;
          margin-right: 8px;
        }

        img {
          max-width: 65px;
          height: auto;
          max-height: 20px;
          object-fit: contain;
          display: inline-block;
        }
      }
    }
  }
`;
